body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #100B1F;
}

code {
  font-family: 'Inter'
}

*{
    -ms-overflow-style: none;
}
::-webkit-scrollbar {
    display: none;
}

#root {
    height: 100vh;
}

.clickable {
    cursor: pointer;
    user-select: none;
}

.clickable:active {
    opacity: .8;
}

.margin-right-desktop {
    margin-right: 0px;
    margin-bottom: 20px;
}

.play-container {
    min-height: 400px;
}

@media only screen and (min-width: 1040px) {
    .margin-right-desktop {
        margin-right: 20px;
        margin-bottom: 0px;
    }
}

@media only screen and (min-width: 1040px) {
    .play-container {
        min-height: 0px;
    }
}

.react-numeric-input {
    height: 40px !important;
    background-color: #1D2432 !important;
    width: 200px !important;
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

input[type="search"] {
    -webkit-appearance: none !important;
    -webkit-border-radius:0px !important;
    border-radius: 0px;
}

.react-numeric-input input {
    height: 40px !important;
    border: none !important;
    background-color: #100B1F !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: auto;
    width: 100px !important;
}

.react-numeric-input b {
    width: 40px !important;
    height: 40px !important;
    top: 0px !important;
    background-color: #100B1F !important;
    color: black !important;
}

.no-select {
    user-select: none;
}

* {
    scrollbar-width: none;
}
  
* {
    box-sizing: border-box;
 }

* {
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

.banner {
    margin-top: 0;
    margin-bottom: 0;
}

.coins {
    height: 600px
}

.invert {
    filter: invert(1);
}

input.middle:focus {
    outline-width: 0;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.react-numeric-input input {
    background-color: #232830;
}

.react-numeric-input b i {
    background: rgba(255, 255, 255, .7) !important;
    cursor: pointer;
}

.react-numeric-input b {
    cursor: pointer !important;
}

.coin-container {
    flex-direction: row;
    display: flex;
}

@media only screen and (max-width: 600px) {
    .container {
        flex-wrap: wrap;
    }
    .banner {
        display: none;
    }
    .connect-wallet {
        margin: auto;
    }

    .coin-container {
        flex-direction: column !important;
    }

    .coins {
        height: 100px !important;
    }

    .connect {
        display: none !important;
    }

    .description {
        padding-right: 20px !important;
    }
    .description p {
        text-align: center;
        font-size: 15px !important;
    }
}